import { EnumLegendMap } from '../types';

import styles from './Definition.module.scss';

export default function LegendPrecipitation({ type }: { type: EnumLegendMap }) {
	const titleLegend =
		type === EnumLegendMap['precipitation-drizzle'] ||
		type === EnumLegendMap['precipitation-hail'] ||
		type === EnumLegendMap['precipitation-lightning']
			? 'mm'
			: 'mm/u';

	return (
		<>
			<p>Neerslag ({titleLegend})</p>
			<dl className={styles.definitionList}>
				<dt className={styles['precipitation-extreme']}></dt>
				<dd>100+</dd>
				<dt className={styles['precipitation-severe']}></dt>
				<dd>10-100</dd>
				<dt className={styles['precipitation-moderate']}></dt>
				<dd>5-10</dd>
				<dt className={styles['precipitation-light']}></dt>
				<dd>2-5</dd>
				<dt className={styles['precipitation-very-light']}></dt>
				<dd>0-2</dd>
				{type === EnumLegendMap['precipitation-drizzle'] ? (
					<>
						<dt className={`${styles.rule} ${styles.drizzle}`}></dt>
						<dd className={styles.rule}>Motregen</dd>
					</>
				) : null}
				{type === EnumLegendMap['precipitation-hail'] ? (
					<>
						<dt className={`${styles.rule} ${styles.hail}`}></dt>
						<dd className={styles.rule}>Hagel</dd>
					</>
				) : null}
				{type === EnumLegendMap['precipitation-lightning'] ? (
					<>
						<dt
							className={`${styles.rule} ${styles.lightning}`}
						></dt>
						<dd className={styles.rule}>Ontlading</dd>
					</>
				) : null}
				{type === EnumLegendMap['precipitation-wetsnow-snow'] ? (
					<>
						<dt
							className={`${styles.rule} ${styles['wet-snow']}`}
						></dt>
						<dd className={styles.rule}>Natte sneeuw</dd>
						<dt className={styles.snow}></dt>
						<dd>Sneeuw</dd>
					</>
				) : null}
				{type === EnumLegendMap['precipitation-snow'] ? (
					<>
						<dt className={`${styles.rule} ${styles.snow}`}></dt>
						<dd className={styles.rule}>Sneeuw</dd>
					</>
				) : null}
			</dl>
		</>
	);
}
